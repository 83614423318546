import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  return (
    <Layout>
      <SEO title={`Polityka cookies`} description={`Polityka cookies`} />

      <div className="offer-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">Polityka cookies</h1>
              <div className="separator-line-horizontal"></div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Informacje o plikach cookie</h2>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                Wszystkie strony internetowe Balicka Clinic korzystają z plików
                cookie. Przeglądając tę stronę i akceptując niniejszą politykę,
                wyrażasz zgodę, aby firma Balicka Clinic korzystała z plików
                cookie zgodnie z warunkami tej polityki. Pliki cookies (tzw.
                ciasteczka) to niewielkie pliki tekstowe wysyłane przez
                odwiedzany serwis internetowy do urządzenia internauty. Nie
                zawierają one żadnych danych osobowych. Istnieją dwa główne
                rodzaje plików cookie: sesyjne i stałe:
                <ul>
                  <li>
                    Pliki sesyjne są usuwane z komputera od razu po zamknięciu
                    przeglądarki internetowej.
                  </li>
                  <li>
                    Pliki stałe pozostają w pamięci komputera aż do momentu ich
                    ręcznego usunięcia lub wygaśnięcia ich ważności.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Pliki cookie firmy Google</h2>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                Balicka Clinic używa narzędzia Google Analytics w celu analizy
                użytkowania swojej strony, dokładny sposób działania i politykę
                prywatności Google Analytics można znaleźć pod adresem:
                http://www.google.com/analytics/learn/privacy.html
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">
                Odmowa przyjmowania plików cookie
              </h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Większość przeglądarek internetowych umożliwia zaakceptowanie
                bądź odmowę przyjmowania plików cookie.
              </p>
              <div className="offer-item-description">
                <ul>
                  <li>
                    W przeglądarce Internet Explorer możesz wyłączyć
                    przyjmowanie plików cookie, klikając "Narzędzia", "Opcje
                    internetowe", "Prywatność", a następnie wybierając
                    odpowiednią opcję.
                  </li>
                  <li>
                    W przeglądarce Firefox możesz zmienić ustawienia plików
                    cookie, klikając kolejno "Narzędzia", "Opcje", "Prywatność"
                    i "Historia".
                  </li>
                  <li>
                    W przeglądarce Safari możesz zmienić ustawienia plików
                    cookie, klikając kolejno "Preferencje", "Prywatność" i
                    następnie "Pliki cookies".
                  </li>
                  <li>
                    W przeglądarce Chrome możesz zmienić ustawienia plików
                    cookie, klikając kolejno "Ustawienia", "Prywatność",
                    "Ustawienia treści" i "Pliki cookies".
                  </li>
                  <li>
                    W przeglądarce Opera możesz zmienić ustawienia plików
                    cookie, klikając kolejno "Preferencje", "Zaawansowane" i
                    następnie "Ciasteczka ".
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
